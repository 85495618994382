import React, {useEffect, useState} from 'react';
import {
  initializeTranslation,
  translateMicrophone,
  stopContinuousTranslation,
  translateClient, translateFile
} from "../ms-translation";

import {OTPublisher, OTSubscriber, OTSession, OTStreams} from 'opentok-react';

const OT = require('@opentok/client');

const apiKey = "46487612";
const sessionId = "1_MX40NjQ4NzYxMn5-MTU3ODQyODQ0MTQ4NX5oMDhMSjQra2RjWWhzQ3ZGZVI4VFdRTTZ-fg";
let mediaStreamTrack;

export default ({
                  token,
                  targetLanguage,
                  sourceLanguage,
                  user,
                  updateTargetLanguage
                }) => {

  const publisherComponent = React.createRef();
  const subscriberComponent = React.createRef();
  const sessionComponent = React.createRef();

  // const [message, updateMessage] = useState("");


  useEffect(() => {
    mediaStreamTrack = initializeTranslation();

    return () => {
      stopContinuousTranslation();
    }
  }, []);

  useEffect(() => {
    if (user === 'client' && sourceLanguage !== 'en-US') {
      sessionComponent.current.sessionHelper.session.signal({
        type: "languageChange",
        data: sourceLanguage
      });
    }
  }, [sourceLanguage]);

  useEffect(() => {
    if (sourceLanguage !== targetLanguage && user === 'guide') {
      const subscriber = subscriberComponent.current.getSubscriber();
      subscriber.setAudioVolume(10);
      const tracks = subscriber.videoElement().srcObject.getAudioTracks();
        var subscriberMediaStream = new MediaStream(tracks);
        stopContinuousTranslation();
        translateMicrophone(sourceLanguage, targetLanguage, sendMessage);
        translateClient(targetLanguage, sourceLanguage, subscriberMediaStream, sendMessage);

        publisherComponent.current.getPublisher().setAudioSource(mediaStreamTrack);
    }
  }, [sourceLanguage, targetLanguage]);

  const sendMessage = (message_to_send) => {
    console.log('sending message', message_to_send);

    // sessionComponent.current.sessionHelper.session.signal({
    //   type: "message",
    //   data: message_to_send
    // });
  };

  const handleSignal = (e) => {

    if (user === 'guide' && e.type === "signal:languageChange" && e.data !== targetLanguage) {
      updateTargetLanguage(e.data);
    }

    // if (e.type === "signal:message") {
    //   console.log('***** receiving signal:', e.data);
    //   if(e.data === "clear") {
    //     updateMessage("");
    //   }
    //   else {
    //     updateMessage(e.data);
    //   }
    // }
  };

  return (
    <div id="videos">
      <OTSession
        ref={sessionComponent}
        apiKey={apiKey} sessionId={sessionId} token={token}
        eventHandlers={{
          signal: handleSignal
        }}
      >
        <div id={"subscriber"}>
          <OTStreams>
            <OTSubscriber
              ref={subscriberComponent}

              properties={{
                width: '100%',
                height: 'calc(100vh - 235px)',
                fitMode: 'contain'
              }}/>
          </OTStreams>
        </div>
          <div id="publisher">
            <OTPublisher
              ref={publisherComponent}
              properties={{
                insertMode: 'append',
                width: '100%',
                height: '192px'
              }}
            />
          </div>

        {/*<div id={"message"}>*/}
        {/*  <p>{message}</p>*/}
        {/*</div>*/}

      </OTSession>
    </div>
  );
}