import React, {useState} from 'react';
import LiveStream from "./LiveStream";

export default () => {
  const [sourceLanguage, setSourceLanguage] = useState("en-US");
  const targetLanguage = 'en-US';

  const token = "T1==cGFydG5lcl9pZD00NjQ4NzYxMiZzaWc9NDlhMjU4MDk5ZTQwNDVhZTNkODQwYzcxMWRmZjRlYjEyMDljODczZDpzZXNzaW9uX2lkPTFfTVg0ME5qUTROell4TW41LU1UVTNPRFF5T0RRME1UUTROWDVvTURoTVNqUXJhMlJqV1doelEzWkdaVkk0VkZkUlRUWi1mZyZjcmVhdGVfdGltZT0xNTgxMzQ2NDU3Jm5vbmNlPTAuNTc1NTg1NTQ3MzYwNDAyNiZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNTgzOTM0ODU2JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9";

  return (
    <div>
      <LiveStream
        token={token}
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        user='client'
      />

      <div className="controls">
        <p><strong>Client</strong></p>
        <label htmlFor="languageOptions">Set Language - </label>
        <select id="languageOptions"
                onChange={(e) => setSourceLanguage(e.target.value)}
                value={sourceLanguage}>

          <option>Choose Language</option>
          <option value="ar-EG">Arabic - EG</option>
          <option value="ca-ES">Catalan - ES</option>
          {/*<option value="da-DK">Danish - DK</option>*/}
          <option value="da-DK">Danish - DK</option>
          <option value="de-DE">German - DE</option>
          {/*<option value="en-AU">English - AU</option>*/}
          {/*<option value="en-CA">English - CA</option>*/}
          {/*<option value="en-GB">English - GB</option>*/}
          {/*<option value="en-IN">English - IN</option>*/}
          {/*<option value="en-NZ">English - NZ</option>*/}
          <option value="en-US">English - US</option>
          <option value="es-ES">Spanish - ES</option>
          {/*<option value="es-MX">Spanish - MX</option>*/}
          <option value="fi-FI">Finnish - FI</option>
          {/*<option value="fr-CA">French - CA</option>*/}
          <option value="fr-FR">French - FR</option>
          <option value="hi-IN">Hindi - IN</option>
          <option value="it-IT">Italian - IT</option>
          <option value="ja-JP">Japanese - JP</option>
          <option value="ko-KR">Korean - KR</option>
          <option value="nb-NO">Norwegian - NO</option>
          <option value="nl-NL">Dutch - NL</option>
          <option value="pl-PL">Polish - PL</option>
          <option value="pt-BR">Portuguese - BR</option>
          <option value="pt-PT">Portuguese - PT</option>
          <option value="ru-RU">Russian - RU</option>
          <option value="sv-SE">Swedish - SE</option>
          <option value="zh-CN">Chinese - CN</option>
          <option value="zh-HK">Chinese - HK</option>
          <option value="zh-TW">Chinese - TW</option>
        </select>
        <p>
          Source Language: {sourceLanguage}
        </p>
        <p>
          Target Language: {targetLanguage}
        </p>
      </div>
    </div>
  );
}
