import React, {useState} from 'react';
import '../styles/index.scss';
import ClientApp from './ClientApp';
import GuideApp from './GuideApp';

export default () => {

  const [user, setUser] = useState(null);
  let app = <div/>;

  if (user === null) {
    app = (
      <div className={"button-container"}>
        <h1>VMT Live Stream Translation POC</h1>
        <p>
          <button className={"button"} onClick={() => setUser('client')}>Client</button>
          <button className={"button"} onClick={() => setUser('guide')}>Guide</button>
        </p>
      </div>
    );
  }
  else {
    app = user === 'client' ? <ClientApp /> : <GuideApp />;
  }

  return app;
}
