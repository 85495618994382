import React, {useState} from 'react';
import LiveStream from "./LiveStream";

export default () => {
  const sourceLanguage = 'en-US';

  const [targetLanguage, updateTargetLanguage] = useState('en-US');

  const token = "T1==cGFydG5lcl9pZD00NjQ4NzYxMiZzaWc9OWExYzc3ODRlMzQ3NzVmNDM5MWJlNjMyNDBiMGUwOWE0ODViMWQ4ZTpzZXNzaW9uX2lkPTFfTVg0ME5qUTROell4TW41LU1UVTNPRFF5T0RRME1UUTROWDVvTURoTVNqUXJhMlJqV1doelEzWkdaVkk0VkZkUlRUWi1mZyZjcmVhdGVfdGltZT0xNTgxMzQ2NDI5Jm5vbmNlPTAuMjYxNDQyMjgxMzUyMTM0NjUmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTU4MzkzNDgyOSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==";

  return (
    <div>

      <LiveStream
        token={token}
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        updateTargetLanguage={updateTargetLanguage}
        user='guide'
      />


      <div className="controls">
        <p><strong>Guide</strong></p>
        <p>
          Source Language: {sourceLanguage}
        </p>
        <p>
          Target Language: {targetLanguage}
        </p>
      </div>
    </div>
  );
}
