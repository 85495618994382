// On document load resolve the Speech SDK dependency
import audioBufferToWav from "./helpers/audiobufferToWav"
import {record, releaseMediaResources} from "./helpers/PCMRecorder"

var SpeechSDK = require('microsoft-cognitiveservices-speech-sdk');
var reco;
var clientReco;
var mediaStreamDestination;
var mediaStreamTrack;
var soundContext = undefined;

// API Config Options
var subscriptionKey;
var region;
var clientRecording;
var pushStream;

export function initializeTranslation() {
  subscriptionKey = "f2c20d3ddd0c44888938df2e44e15f9a";
  region = 'eastus';

  try {
    var AudioContext = window.AudioContext // our preferred impl
      || window.webkitAudioContext       // fallback, mostly when on Safari
      || false;                          // could not find.
    if (AudioContext) {
      soundContext = new AudioContext();

      mediaStreamDestination = soundContext.createMediaStreamDestination();

      mediaStreamTrack = mediaStreamDestination.stream.getAudioTracks()[0];

      return mediaStreamTrack;
    } else {
      alert("Audio context not supported");
    }
  } catch (e) {
    window.console.log("no sound context found, no audio output.", e);
  }
}

function getTranslationRecognizerConfigs(sourceLanguage, targetLanguage, input) {
  console.log("Creating Recognizer", input);
  var audioConfig = input === 'microphone' ? SpeechSDK.AudioConfig.fromDefaultMicrophoneInput() : SpeechSDK.AudioConfig.fromStreamInput(input);
  console.log("AudioConfig");

  var speechConfig = SpeechSDK.SpeechTranslationConfig.fromSubscription(subscriptionKey, region);

  // Set the source language.
  speechConfig.speechRecognitionLanguage = sourceLanguage;

  // Defines the language(s) that speech should be translated to.
  // Multiple languages can be specified for text translation and will be returned in a map.

  speechConfig.addTargetLanguage(targetLanguage);

  speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_TranslationVoice, targetLanguage);

  return {audioConfig, speechConfig}
}

export function translateClient(sourceLanguage, targetLanguage, stream, sendMessage) {
  console.log("Client Start");
  console.log("Creating Push Stream");
  pushStream = SpeechSDK.AudioInputStream.createPushStream();

  var clientConfigs = getTranslationRecognizerConfigs(sourceLanguage, targetLanguage, pushStream, sendMessage);
  clientReco = createRecognizer(clientConfigs.speechConfig, clientConfigs.audioConfig, sendMessage);

  clientReco.synthesizing = function (s, e) {
    console.log('synthesizing', e, s);
    if (e.result.audio && soundContext) {
      console.log("Playing Audio");

      var source = soundContext.createBufferSource();

      soundContext.decodeAudioData(e.result.audio, function (newBuffer) {
        source.buffer = newBuffer;
        source.connect(soundContext.destination);
        source.start(0);
      });
    }
  };
  console.log("Starting Recognition");

  clientReco.startContinuousRecognitionAsync();
  record(soundContext, stream, pushStream);


}

export function translateFile(event) {
  console.log(event.target);
  var input = event.target.files[0];
  var arrayBuffer;

  var fileReader = new FileReader();
  fileReader.onload = function (event) {
    arrayBuffer = event.target.result;
  };
  fileReader.readAsArrayBuffer(input);
  fileReader.onloadend = function (d) {
    console.log("Decoding data");
    console.log(fileReader.result);
    soundContext.decodeAudioData(
      fileReader.result,
      function (buffer) {
        console.log("Succeeded Decoding");

        console.log(buffer);
        var wav = audioBufferToWav(buffer);
        pushStream.write(wav);
      },
      function (e) {
        console.log("Failed Decoding");

        console.log(e);
      }
    );
  };
}

export function translateMicrophone(sourceLanguage, targetLanguage, sendMessage) {

  var guideConfigs = getTranslationRecognizerConfigs(sourceLanguage, targetLanguage, 'microphone');

  reco = createRecognizer(guideConfigs.speechConfig, guideConfigs.audioConfig, sendMessage);
  reco.synthesizing = function (s, e) {
    console.log('synthesizing', e, s);

    if (e.result.audio && soundContext) {
      console.log("Playing Audio");

      var source = soundContext.createBufferSource();

      soundContext.decodeAudioData(e.result.audio, function (newBuffer) {
        source.buffer = newBuffer;
        source.connect(mediaStreamDestination);
        source.start(0);
      });
    }
  };
  reco.startContinuousRecognitionAsync();

}


function createRecognizer(speechConfig, audioConfig, sendMessage) {
  const translationRecognizer = new SpeechSDK.TranslationRecognizer(speechConfig, audioConfig);

  // Before beginning speech recognition, setup the callbacks to be invoked when an event occurs.
  // The event recognizing signals that an intermediate recognition result is received.
  // You will receive one or more recognizing events as a speech phrase is recognized, with each containing
  // more recognized speech. The event will contain the text for the recognition since the last phrase was recognized.
  // Both the source language text and the translation text(s) are available.
  translationRecognizer.recognizing = function (s, e) {
    console.log('recognizing', e);
    sendMessage("Participant is speaking...");
  };


  // The event recognized signals that a final recognition result is received.
  // This is the final event that a phrase has been recognized.
  // For continuous recognition, you will get one recognized event for each phrase recognized.
  // Both the source language text and the translation text(s) are available.

  translationRecognizer.recognized = function (s, e) {
    console.log('recognized', e);
    sendMessage("Speech Recognized...Translating...");
  };

  // The event signals that the service has stopped processing speech.
  // https://docs.microsoft.com/javascript/api/microsoft-cognitiveservices-speech-sdk/translationrecognitioncanceledeventargs?view=azure-node-latest
  // This can happen for two broad classes of reasons.
  // 1. An error is encountered.
  //    In this case the .errorDetails property will contain a textual representation of the error.
  // 2. No additional audio is available.
  //    Caused by the input stream being closed or reaching the end of an audio file.
  translationRecognizer.canceled = function (s, e) {
    console.log('canceled', e);

  };


  // Signals an audio payload of synthesized speech is ready for playback.
  // If the event result contains valid audio, it's reason will be ResultReason.SynthesizingAudio
  // Once a complete phrase has been synthesized, the event will be called with ResultReason.SynthesizingAudioComplete and a 0 byte audio payload.


  translationRecognizer.synthesizing = function (s, e) {
    console.log('synthesizing', e, s);
    //sendMessage("clear");
    console.log(e.result);

    if (e.result.audio && soundContext) {
      console.log("Playing Audio");

      var source = soundContext.createBufferSource();

      soundContext.decodeAudioData(e.result.audio, function (newBuffer) {
        source.buffer = newBuffer;
        //source.connect(mediaStreamDestination);
        source.connect(soundContext.destination);
        source.start(0);
      });
    }
  };

  // Signals that a new session has started with the speech service
  translationRecognizer.sessionStarted = function (s, e) {
    console.log('session started', e);
  };

  // Signals the end of a session with the speech service.
  translationRecognizer.sessionStopped = function (s, e) {
    console.log('session stopped', e);
  };

  // Signals that the speech service has started to detect speech.
  translationRecognizer.speechStartDetected = function (s, e) {
    console.log('speech start detected', e);

  };

  // Signals that the speech service has detected that speech has stopped.
  translationRecognizer.speechEndDetected = function (s, e) {
    console.log('speech end detected', e);

  };

  return translationRecognizer;
}

export function requestData() {
  console.log("Requesting Data Button");

  clientRecording.requestData()
}


export function stopContinuousTranslation() {
  if (reco) {
    reco.stopContinuousRecognitionAsync(
      function () {
        reco.close();
        reco = undefined;
      },
      function (err) {
        reco.close();
        reco = undefined;
      });
  }
}