import audioBufferToWav from "./audiobufferToWav"

let privMediaResources;
let privSpeechProcessorScript;
let isEnd = false;


//pass
export const record = (context, mediaStream, outputStream) => {
  //setTimeout(() => isEnd = true, 5000)
  const desiredSampleRate = 16000;

  const scriptNode = (() => {
    let bufferSize = 0;
    try {
      return context.createScriptProcessor(bufferSize, 1, 1);
    } catch (error) {
      // Webkit (<= version 31) requires a valid bufferSize.
      bufferSize = 2048;
      let audioSampleRate = context.sampleRate;
      while (bufferSize < 16384 && audioSampleRate >= (2 * desiredSampleRate)) {
        bufferSize <<= 1;
        audioSampleRate >>= 1;
      }
      return context.createScriptProcessor(bufferSize, 1, 1);
    }
  })();

  console.log("Start Recording Sample Rate", context.sampleRate, desiredSampleRate);
  //const waveStreamEncoder = new RiffPcmEncoder(context.sampleRate, desiredSampleRate);
  let needHeader = true;
  const that = this;
  //audioprocessingevent
  scriptNode.onaudioprocess = (event) => {

    //const inputFrame = event.inputBuffer.getChannelData(0);
    //const inputFrame2 = event.inputBuffer.getChannelData(1);


    if (outputStream && !outputStream.isClosed) {
      const waveFrame = audioBufferToWav(event.inputBuffer);
      if (!!waveFrame) {
        outputStream.write(waveFrame);
        if (isEnd) {
          console.log("wrote is end frame");
        }
        needHeader = false;
      }
    }
  };

  const micInput = context.createMediaStreamSource(mediaStream);

  // https://webaudio.github.io/web-audio-api/#audioworklet
  // Using AudioWorklet to improve audio quality and avoid audio glitches due to blocking the UI thread

  /*
  if (!!privSpeechProcessorScript && !!context.audioWorklet) {
    context.audioWorklet
      .addModule(privSpeechProcessorScript)
      .then(() => {
        const workletNode = new AudioWorkletNode(context, "speech-processor");
        //messageevent
        workletNode.port.onmessage = (ev) => {
          //float32array
          const inputFrame = ev.data;

          if (outputStream && !outputStream.isClosed) {
            const waveFrame = waveStreamEncoder.encode(needHeader, inputFrame);
            if (!!waveFrame) {
              console.log("outputStream write 2")

              outputStream.write({
                buffer: waveFrame,
                isEnd: false,
                timeReceived: Date.now(),
              });
              needHeader = false;
            }
          }
        };
        micInput.connect(workletNode);
        workletNode.connect(context.destination);
        privMediaResources = {
          scriptProcessorNode: workletNode,
          source: micInput,
          stream: mediaStream,
        };
      })
      .catch(() => {
        micInput.connect(scriptNode);
        scriptNode.connect(context.destination);
        privMediaResources = {
          scriptProcessorNode: scriptNode,
          source: micInput,
          stream: mediaStream,
        };
      });
  } else {

   */
  micInput.connect(scriptNode);
  scriptNode.connect(context.destination);
  privMediaResources = {
    scriptProcessorNode: scriptNode,
    source: micInput,
    stream: mediaStream,
  };
  //}
};